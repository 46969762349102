import React, {useEffect, useState} from 'react';
import "../../assets/css/forms.sass"
import {faAngleDoubleRight} from "@fortawesome/free-solid-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {useRegistreer} from "../../data/UseAuthenticationData";
import {Col, Row} from "react-bootstrap";
import {Link, useNavigate} from "react-router-dom";
import {
    validateEmail,
    validateFamilienaam,
    validatePhoneNumber, validateRekeningnummer, validateVereniging,
    validateVoornaam,
    validateWachtwoord
} from "../../util/validator";

function RegistreerForm() {
    const navigate = useNavigate();
    const [error, setError] = useState({message: ""})
    const [registreerData, setRegistreerData] = useState({
        voornaam: "",
        familienaam: "",
        telefoon: "",
        email: "",
        wachtwoord: "",
        confirm_wachtwoord: "",
        vereniging: "",
        rekeningnummer: ""
    })
    const {mutate: registreer, isLoading, isError, error: authError, data, isSuccess} = useRegistreer()

    const changeValue = (e) => {
        setRegistreerData((old) => ({...old, [e.target.name]: e.target.value}))
    }

    const changeKeyValue = (key, value) => {
        setRegistreerData((old) => ({...old, [key]: value}))
    }

    const handleRegistreer = () => {
        if (controlData()) {
            registreer(registreerData)
        }
    }

    const controlData = () => {
        setError({message: ""})
        const vnaam = validateVoornaam(registreerData.voornaam)
        if (vnaam) {
            changeKeyValue("voornaam", vnaam)
        } else {
            setError({message: "Ongeldige voornaam"})
            return false
        }
        const fnaam = validateFamilienaam(registreerData.familienaam)
        if (fnaam) {
            changeKeyValue("familienaam", fnaam)
        } else {
            setError({message: "Ongeldige familienaam"})
            return false
        }
        const email = validateEmail(registreerData.email)
        if (email) {
            changeKeyValue("email", email)
        } else {
            setError({message: "Ongeldige e-mailadres"})
            return false
        }
        const tel = validatePhoneNumber(registreerData.telefoon)
        if (tel) {
            changeKeyValue("telefoon", tel)
        } else {
            setError({message: "Ongeldig telefoonnummer"})
            return false
        }
        const ww = validateWachtwoord(registreerData.wachtwoord)
        if (!ww) {
            setError({message: "Ongelig wachtwoord. Het wachtwoord moet minimaal 8 karakters lang zijn. Minimaal 1 letter, 1 cijfer en 1 speciaal teken bevatten."})
            return false
        }
        if (registreerData.wachtwoord !== registreerData.confirm_wachtwoord) {
            setError({message: "Wachtwoorden komen niet overeen"})
            return false
        }
        const vereniging = validateVereniging(registreerData.vereniging)
        if (!vereniging) {
            changeKeyValue("vereniging", "Particulier")
        }

        const reknr = validateRekeningnummer(registreerData.rekeningnummer)
        if (reknr) {
            changeKeyValue("rekeningnummer", reknr)
        } else {
            setError({message: "Ongeldig IBAN-nummer"})
            return false
        }
        return true

    }

    useEffect(() => {
        if (isSuccess && !isError) {
            localStorage.setItem("token", data?.data?.token)
            navigate("/bekerverhuur/reserveer")
        } else if (isError) {
            setError({message: authError.response?.data?.message})
        }
    }, [isSuccess, isError, data, authError, navigate])


    return (
        <Row className={"mt-4 form"}>
            <Col md={12}>
                <h2>Registreren</h2>
                <h4 className={"ml-1 mt-4"}>Persoonlijk</h4>
            </Col>
            <Col md={{span: 5}}>
                <div className={"text-container"}>
                    <input type="text" name="voornaam" value={registreerData.voornaam} onChange={changeValue}
                           disabled={isLoading}/>
                    <span className={(registreerData.voornaam !== "" ? "active" : "")}>voornaam</span>
                </div>
            </Col>
            <Col md={{span: 5, offset: 1}}>
                <div className={"text-container"}>
                    <input type="text" name="familienaam" value={registreerData.familienaam} onChange={changeValue}
                           disabled={isLoading}/>
                    <span className={(registreerData.familienaam !== "" ? "active" : "")}>familienaam</span>
                </div>
            </Col>
            <Col md={{span: 5}}>
                <div className={"text-container"}>
                    <input type="email" name="email" value={registreerData.email} onChange={changeValue}
                           disabled={isLoading}/>
                    <span className={(registreerData.email !== "" ? "active" : "")}>e-mailadres</span>
                </div>
            </Col>
            <Col md={{span: 5, offset: 1}}>
                <div className={"text-container"}>
                    <input type="text" name="telefoon" value={registreerData.telefoon} onChange={changeValue}
                           disabled={isLoading}/>
                    <span className={(registreerData.telefoon !== "" ? "active" : "")}>telefoonnummer</span>
                </div>
            </Col>
            <Col md={{span: 5}}>
                <div className={"text-container"}>
                    <input type="password" name="wachtwoord" value={registreerData.wachtwoord} onChange={changeValue}
                           disabled={isLoading}/>
                    <span className={(registreerData.wachtwoord !== "" ? "active" : "")}>wachtwoord</span>
                </div>
            </Col>
            <Col md={{span: 5, offset: 1}}>
                <div className={"text-container"}>
                    <input type="password" name="confirm_wachtwoord" value={registreerData.confirm_wachtwoord}
                           onChange={changeValue}
                           disabled={isLoading}/>
                    <span
                        className={(registreerData.confirm_wachtwoord !== "" ? "active" : "")}>wachtwoord opnieuw</span>
                </div>
            </Col>
            <Col md={12} className={"mt-5 ml-1"}>
                <h4>Vereniging</h4>
            </Col>
            <Col md={{span: 5}}>
                <div className={"text-container"}>
                    <input type="text" name="vereniging" value={registreerData.vereniging} onChange={changeValue}
                           disabled={isLoading}/>
                    <span className={(registreerData.vereniging !== "" ? "active" : "")}>vereniging</span>
                </div>
            </Col>
            <Col md={{span: 5, offset: 1}}>
                <div className={"text-container"}>
                    <input type="email" name="rekeningnummer" value={registreerData.rekeningnummer}
                           onChange={changeValue}
                           disabled={isLoading}/>
                    <span className={(registreerData.rekeningnummer !== "" ? "active" : "")}>rekeningnummer</span>
                </div>
            </Col>
            <Col md={12}>
                <div className={"error-container"}>
                    {error.message !== "" ? (<p>{error.message}</p>) : null}

                </div>
                <Row className={"mt-3"}>
                    <Col md={1}>
                        <button onClick={handleRegistreer} className="float-left form-btn" name="login"
                                disabled={isLoading}>Registreer <FontAwesomeIcon icon={faAngleDoubleRight}/>
                        </button>
                    </Col>
                    <Col md={11}>
                        <p>Heeft u al een account? Log dan <Link to={"/verhuur/login"}>hier</Link> in</p>
                    </Col>
                </Row>
            </Col>
        </Row>
    );
}

export default RegistreerForm;
