import React, {Fragment} from 'react';
import {Col, Container, Row} from "react-bootstrap";
import Bestuurslid from "./Bestuurslid";
import {useBestuursledenData} from "../../data/UseBestuursledenData";

function Bestuursleden() {
    const {isLoading, data} = useBestuursledenData()

    if (isLoading) {
        return <div>Loading...</div>
    }

    return (
        <section className={"bestuursleden"}>
            <Container>
                <Row>
                    <Col sm={12}>
                        <p>
                            De helden die voor u het jeugdhuis uitbaten hebben vele talenten, die zij ten volle benutten
                            om van ODT een nog leukere plek te maken! Samen zorgen zij ervoor dat het jeugdhuis voldoende drank
                            heeft en leuke evenementen kan organiseren. Lees hier meer over hun dromen met het jeugdhuis en welke dingen
                            ze allemaal voor hun rekening nemen. Wil je zelf ook deel uitmaken van deze groep en het
                            jeugdhuis verder vooruit helpen kan je steeds contact opnemen met iemand van het bestuur!
                        </p>
                    </Col>
                    {data?.map((afdeling, index) => (
                        <Fragment key={index}>
                            <Col sm={12}>
                                <h5>{afdeling.naam}</h5>
                            </Col>
                            {afdeling.bestuursleden.map((bestuurslid, i) => {
                                return (<Bestuurslid key={i} bestuurslid={bestuurslid}/>
                                )
                            })}
                        </Fragment>
                    ))}
                </Row>
            </Container>
        </section>
    );
}

export default Bestuursleden;
