import React, {Fragment} from 'react';
import LoginForm from "../components/verhuurpage/LoginForm";
import "../assets/css/forms.sass"
import {Col, Container, Row} from "react-bootstrap";
import RegisterRedirect from "../components/verhuurpage/RegisterRedirect";
import NavBar from "../components/global/NavBar";
import HeaderSmall from "../components/global/HeaderSmall";
import AnimationContainer from "../components/global/AnimationContainer";
import {Helmet} from "react-helmet";

function LoginPage() {
    return (
        <Fragment>
            <Helmet>
                <title>ODT - Inloggen</title>
                <meta name="description" content="Bij Onder De Toren denken we ook aan het milieu. Daarom kan u via onderstaande knop onze Tritan-bekers voor uw evenement huren."/>
            </Helmet>
            <NavBar/>
            <AnimationContainer>
                <HeaderSmall title={"Bekerverhuur"}/>
                <Container className="mt-5">
                    <Row>
                        <Col md={6}>
                            <h4>Inloggen</h4>
                            <LoginForm/>
                        </Col>
                        <Col md={6}>
                            <h4>Registreren</h4>
                            <RegisterRedirect/>
                        </Col>
                    </Row>
                </Container>
            </AnimationContainer>
        </Fragment>
    );
}

export default LoginPage;
