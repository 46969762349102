import React, {Fragment} from 'react';
import HeaderSmall from "../components/global/HeaderSmall";
import Menu from "../components/assortimentpage/Menu";
import Assortiment from "../components/assortimentpage/Assortiment";
import DrankVdMaand from "../components/assortimentpage/DrankVdMaand";
import NavBar from "../components/global/NavBar";
import AnimationContainer from "../components/global/AnimationContainer";
import {Helmet} from "react-helmet";

function AssortimentPage() {

    return (
        <Fragment>
            <Helmet>
                <title>ODT - Assortiment</title>
                <meta name="description" content="In het jeugdhuis bieden wij u met alle plezier een breed assortiment aan bieren en frisdranken aan. Een overzicht ervan kan u hier terugvinden."/>
            </Helmet>
            <NavBar/>
            <AnimationContainer>
                <HeaderSmall title={"Assortiment"}/>
                <Menu/>
                <Assortiment/>
                <DrankVdMaand/>
            </AnimationContainer>
        </Fragment>
    );
}

export default AssortimentPage;
