import React, {useState} from 'react';
import StevenJambon from "./StevenJambon";

function MadeBy() {
    const [emoji, setEmoji] = useState("❤️")

    const randomEmoji = () => {
        const emojis = ["❤️", "💙", "💜", "💛", "💚", "🧡", "🤍", "🤎", "🖤"]
        return emojis[Math.floor(Math.random() * emojis.length)]
    }

    const changeEmoji = () => {
        setEmoji(randomEmoji())
    }

    return (
        <p className={"text-right user-select-none"}>Gemaakt met <span onClick={changeEmoji} className="user-select-none" onMouseEnter={changeEmoji}>{emoji}</span>️
            door <StevenJambon/></p>
    );
}

export default MadeBy;
