import React, {Fragment} from 'react';
import HeaderSmall from "../components/global/HeaderSmall";
import Contact from "../components/contactpage/Contact";
import NavBar from "../components/global/NavBar";
import AnimationContainer from "../components/global/AnimationContainer";
import {Helmet} from "react-helmet";

function ContactPage() {
    return (
        <Fragment>
            <Helmet>
                <title>ODT - Contact</title>
                <meta name="description" content="Heb je een vraag of zit je met een probleem? Aarzel zeker niet om ons te contacteren als je een wild idee of een simpele vraag hebt!"/>
            </Helmet>
            <NavBar/>
            <AnimationContainer>
                <HeaderSmall title={"Contact"}/>
                <Contact/>
            </AnimationContainer>
        </Fragment>
    );
}

export default ContactPage;
