import React, {useEffect} from 'react';
import {Col} from "react-bootstrap";

function Bekertype({
                       naam,
                       maxAantal,
                       huurprijs,
                       waarborgprijs,
                       inhoud,
                       aantalBekersPerDoos,
                       updateReservatie,
                       gekozenAantal
                   }) {

    useEffect(() => {
        updateReservatie(naam, 0)
    }, [])

    const updateAantal = (e) => {
        const val = e.target.value
        if (val >= 0 && val <= maxAantal) {
            updateReservatie(naam, val)
        } else if (!val) {
            updateReservatie(naam, 0)
        } else if (val > maxAantal) {
            updateReservatie(naam, maxAantal)
        }
    }

    return (
        <Col lg={3} md={4} sm={6} xs={12} className={"form mt-3"}>
            <h6>{naam} ({inhoud} Cl)</h6>
            <div className={"pl-1"}>
                <p>Bekers/doos: {aantalBekersPerDoos}</p>
                <p>Aantal bekers beschikbaar: {maxAantal}</p>
                <p>Waarborg/beker: € {(waarborgprijs).toFixed(2)}</p>
                <p>Prijs/beker: € {(huurprijs).toFixed(2)}</p>
            </div>
            <div className={"pl-1 pr-1"}>
                <div className={"text-container"}>
                    <input
                        type={"number"}
                        step={aantalBekersPerDoos}
                        value={gekozenAantal ? gekozenAantal : 0}
                        onChange={updateAantal}
                    />
                    <span className={(maxAantal ? "active" : "")}>aantal</span>
                </div>
            </div>
        </Col>
    );
}

export default Bekertype;
