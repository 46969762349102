import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import '@fortawesome/free-solid-svg-icons'
import PropType from "prop-types";
import {Col} from "react-bootstrap";

function Feature({icon, title, description}) {
    return (
        <Col className="feature-item" md={3} sm={6}>
            <div className="feature-item-hover">
                <div className="item-head">
                    <FontAwesomeIcon icon={icon}/>
                </div>
                <div style={{lineHeight: "2rem", height:"4rem", overflow:"hidden"}}>
                    <h6>
                        {title}
                    </h6>
                </div>

            </div>
            <p style={{textAlign: "justify"}}>{description}</p>
        </Col>
    );
}

Feature.propTypes = {
    icon: PropType.string,
    title: PropType.string,
    description: PropType.string
}

Feature.defaultProps = {
    icon: "faBeer",
    title: "Title",
    description: "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Pretium fusce id velit ut tortor."
}

export default Feature;
