import React, {Fragment} from 'react';
import {Col, Container, Row, Table} from "react-bootstrap";
import moment from "moment";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faAngleDoubleRight} from "@fortawesome/free-solid-svg-icons";
import {useNavigate} from "react-router-dom";
import useWindowDimensions from "../../hooks/WindowDimensions";

function ReservatieSucces({reservatie, isSucces}) {
    const navigate = useNavigate()
    const begindatum = moment(reservatie.begindatum).format("DD/MM/YYYY")
    const einddatum = moment(reservatie.einddatum).format("DD/MM/YYYY")
    const dim = useWindowDimensions()

    if (!isSucces) {
        return <div></div>
    }

    const handleBackToHome = () => {
        navigate("/")
    }

    return (
        <Container>
            <Row className={"form"}>
                <Col md={12} className={"mt-4"}>
                    <h3 className={"text-center"}>Reservatie succesvol geplaatst</h3>
                </Col>
                <Col md={12} className={"mt-1 mb-0 tappen"}>
                    <p>
                        Bedankt voor je reservatie, als alles goed is heb je hiervan ook een mailtje ontvangen.<br/>
                        Goed ontvangen? Check dan zeker de bijlage en bevestig je bestelling door binnen de aangegeven termijn het aangegeven bedrag over te maken op ons rekeningnummer.<br/>
                        Niks ontvangen? Check zeker je spam, als deze ook leeg blijft kan je steeds met ons contact opnemen via bekerverhuur@jhodt.be
                    </p>
                </Col>
                <Col md={6} className={"mt-4"}>
                    <h6>Huurder</h6>
                    <div className={"pl-2"}>
                        <p>{reservatie.klant.voornaam} {reservatie.klant.familienaam}</p>
                        <p>{reservatie.klant.vereniging}</p>
                        <p>{reservatie.klant.telefoon}</p>
                        <p>{reservatie.klant.email}</p>
                        <p>{reservatie.klant.rekeningnummer}</p>
                    </div>
                </Col>
                <Col md={6} className={"mt-4"}>
                    <h6>Periode</h6>
                    <div className={"pl-2"}>
                        <p>{begindatum} - {einddatum}</p>
                    </div>
                    <h6 className={"mt-4"}>Volgnummer</h6>
                    <div className={"pl-2"}>
                        <p>{reservatie.reservatienr}</p>
                    </div>
                </Col>
                <Col md={12} className={"mt-4"}>
                    <h6>Bekers</h6>
                    <Table borderless className={"pl-1"}>
                        <thead>
                        <tr>
                            <th scope="col">Doos</th>
                            <th scope="col">Bekertype</th>
                            <th scope="col">Aantal bekers</th>
                            {dim.width >= 600 ? (
                                <Fragment>
                                    <th scope="col">Huurprijs</th>
                                    <th scope="col">Waarborg</th>
                                </Fragment>
                            ) : null}
                            <th scope="col">Totaalprijs</th>
                        </tr>
                        </thead>
                        <tbody>
                        {reservatie.dozen.map(d => (
                            <tr key={d.naam}>
                                <td>{d.naam}</td>
                                <td>{d.type}</td>
                                <td>{d.aantalBekers}</td>
                                {dim.width >= 600 ? (
                                    <Fragment>
                                        <td>€ {d.huurprijs.toFixed(2).replace(".", ",")}</td>
                                        <td>€ {d.waarborg.toFixed(2).replace(".", ",")}</td>
                                    </Fragment>
                                ): null}
                                <td>€ {(d.waarborg + d.huurprijs).toFixed(2).replace(".", ",")}</td>
                            </tr>
                        ))}
                        </tbody>
                    </Table>
                </Col>
                <Col md={12} className={"mt-4"}>
                    <h6>Totaalprijs</h6>
                    <div className={"pl-2"}>
                        <p>€ {reservatie.dozen.map(d => (d.waarborg + d.huurprijs)).reduce((a, b) => {
                            return a + b
                        }, 0).toFixed(2).replace(".", ",")}</p>
                    </div>
                </Col>
                <Col md={12} className={"form mt-4"}>
                    <button onClick={handleBackToHome} className="float-left form-btn">Terug naar
                        homepagina <FontAwesomeIcon icon={faAngleDoubleRight}/>
                    </button>
                </Col>
            </Row>
        </Container>
    );
}

export default ReservatieSucces;
