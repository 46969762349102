import React, {Fragment} from 'react'
import "../../assets/css/footer.sass"
import {Link} from "react-router-dom";
import {faFacebook, faInstagram} from "@fortawesome/free-brands-svg-icons";
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'
import {Col, Container, Row} from "react-bootstrap";
import Contact from "./Contact";
import moment from "moment";
import MadeBy from "./MadeBy";

function Footer() {
    return (
        <Fragment>
            <Contact/>
            <footer className="main-footer">
                <svg preserveAspectRatio="none" viewBox="0 0 100 102" height="100" width="100%" version="1.1"
                     xmlns="http://www.w3.org/2000/svg" className="white">
                    <path d="M0 0 L50 100 L100 0 Z" fill="#FFFFFF" stroke="#FFFFFF"/>
                </svg>
                <Container>
                    <Row className="footer-widgets">
                        <Col md={6} xs={12}>
                            <div className="footer-widget contact-widget">
                                <img src={process.env.PUBLIC_URL + "/assets/img/logo-min.png"}
                                     className="logo-footer img-responsive"
                                     alt="Footer Logo"/>
                                <ul className="social-icons">
                                    <li>
                                        <a className="facebook" href="https://www.facebook.com/jhodt/">
                                            <FontAwesomeIcon icon={faFacebook}/>
                                        </a>
                                    </li>
                                    <li>
                                        <a className="instagram" href="https://www.instagram.com/jh_odt/">
                                            <FontAwesomeIcon icon={faInstagram}/>
                                        </a>
                                    </li>
                                </ul>
                            </div>
                        </Col>

                        <Col md={3} xs={12}>
                            <div className="footer-widget">
                                <h4>Contactgegevens</h4>
                                <h5>Bestuur</h5>
                                <a href={"mailto:bestuur@jhodt.be"}>bestuur@jhodt.be</a>
                                <h5>Bekerverhuur</h5>
                                <a href={"mailto:bekerverhuur@jhodt.be"}>bekerverhuur@jhodt.be</a>
                                <h5>Torentalenten</h5>
                                <a href={"mailto:torentalenten@jhodt.be"}>torentalenten@jhodt.be</a>
                            </div>
                        </Col>

                        <Col md={3} xs={12}>
                            <div className="footer-widget">
                                <h4>JH Onder De Toren</h4>
                                <ul className="footer-links">
                                    <li><Link to={"/"}>Home</Link></li>
                                    <li><Link to={"/assortiment"}>Assortiment</Link></li>
                                    <li><Link to={"/bekerverhuur"}>Bekerverhuur</Link></li>
                                    <li><Link to={"/bestuursleden"}>Bestuursleden</Link></li>
                                    <li><Link to={"/evenementen"}>Evenementen</Link></li>
                                    <li><Link to={"/contact"}>Contact</Link></li>
                                </ul>
                            </div>
                        </Col>
                    </Row>
                </Container>
                <div className="copyright-section">
                    <Container>
                        <Row>
                            <Col xs={6}>
                                <p className={"user-select-none"}>&copy; {moment().year()} - Onder de Toren vzw</p>
                            </Col>
                            <Col xs={6}>
                                <MadeBy/>
                            </Col>
                        </Row>
                    </Container>
                </div>
            </footer>
        </Fragment>
    );
}

export default Footer;
