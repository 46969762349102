import React, {useEffect} from 'react';
import "../../assets/css/forms.sass"
import {Col, Container, Row} from "react-bootstrap";
import PeriodeSelector from "./PeriodeSelector";
import Bekers from "./Bekers";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faAngleDoubleRight} from "@fortawesome/free-solid-svg-icons";
import {useBeschikbareBekersData} from "../../data/UseBekerverhuurData";

function ReservatieForm({reservatie, setReservatie, updateReservatie, plaatsReservatie}) {
    const {mutate: getBeschikbareBekers, data: beschikbareBekers} = useBeschikbareBekersData()

    useEffect(() => {
        if (reservatie.begindatum !== null && reservatie.einddatum !== null && reservatie.begindatum < reservatie.einddatum) {
            getBeschikbareBekers({
                begindatum: reservatie.begindatum?.toISOString(),
                einddatum: reservatie.einddatum?.toISOString()
            })
        }
    }, [reservatie.begindatum, reservatie.einddatum, getBeschikbareBekers])

    return (
        <Container className={"form"}>
            <Row>
                <Col md={12} className={"mt-4"}>
                    <h4>Werkwijze</h4>
                </Col>
                <Col md={12} className={"mt-1 ml-1 tappen"}>
                    <p>
                        Welkom bij de bekerverhuur van Jeugdhuis Onder de Toren. U zit ongetwijfeld met een aantal vragen (hoe werkt dat spel hier, welke bekers zijn er, wat kost dat?), lees dus vooral verder om alles te weten te komen over onze verhuur.<br/>
                        De bestelling van de bekers gebeurt uitsluitend via onze bestellingstool, deze vindt u hieronder terug. In deze tool kan je het aantal gewenste bekers bestellen, opgelet wij verhuren de bekers niet per eenheid maar per doos. De tool zal automatisch naar boven afronden als het aantal gevraagde bekers niet overeenstemt met het aantal bekers in een doos. U krijgt ook meteen een overzicht van de prijs en waarborg. U kan tot 8 weken op voorhand de bekers bestellen.<br/>
                        Als u het gewenste aantal bekers geselecteerd hebt kan u deze meteen reserveren door op de knop “bestellen” te drukken. U ontvangt vervolgens een bevestigingsmail met daarin alle relevante informatie. Opgelet uw bevestiging is pas definitief wanneer wij het bedrag binnen de aangegeven termijn ontvangen hebben, deze vindt u terug in de bijlage van de mail. Van zodra wij de betaling ontvangen hebben zetten wij de bekers klaar voor u en sturen we u een betalingsbevestiging.<br/>
                        Op het moment dat u langskomt voor de bekers ondertekenen we het contract, goede afspraken maken goede vrienden 😉 Het contract, dat u ook al in bijlage vond van onze mail, geeft meer info over de waarborgregeling, de teruggave van de bekers en de prijs. Bij ondertekening van het contract verbindt u zich ertoe dat de bekers in goede staat terug worden gegeven, indien dit niet het geval is aanvaardt u dat er een bedrag van de waarborg wordt afgehouden.<br/>
                        Wanneer u na het evenement de bekers op het afgesproken tijdstip terug brengt zal er samen nagegaan worden of de bekers compleet en proper zijn. Eventuele manco’s zullen aangegeven worden op het contract en in rekening gebracht worden. Na de teruggave van de bekers zal de waarborg worden teruggestort naar het opgegeven rekeningnummer.
                    </p>
                </Col>
                <PeriodeSelector reservatie={reservatie} updateReservatie={updateReservatie}/>
                <Bekers bekers={beschikbareBekers?.data} setReservatie={setReservatie} reservatie={reservatie}/>
                {reservatie.bekers.some(b => b?.aantal > 0) && (
                    <Col md={12} className={"mt-5"}>
                        <p className={"info"}>Door op onderstaande knop te drukken gaat u akkoord met onze <a
                            href={`${process.env.PUBLIC_URL}/assets/algemenevoorwaarden.pdf`} download>voorwaarden</a>
                        </p>
                        <button className="float-lef form-btn" name="reserveer" onClick={() => {
                            plaatsReservatie(reservatie)
                        }}>Reservatie plaatsen <FontAwesomeIcon
                            icon={faAngleDoubleRight}/></button>
                    </Col>
                )}
            </Row>
        </Container>
    );
}

export default ReservatieForm;
