import React from 'react'
import {Col, Container, Row} from "react-bootstrap"
import "../../assets/css/homepage/testimonials.sass"
import SwiperCore, {Autoplay, EffectFade, Navigation} from 'swiper';
import {Swiper, SwiperSlide} from "swiper/react";
import 'swiper/swiper.scss';
import 'swiper/components/effect-fade/effect-fade.scss'
import 'swiper/components/navigation/navigation.scss'
import testimonials from "../../assets/data/testimonials.json"


function Testimonials(props) {
    SwiperCore.use([Autoplay, EffectFade, Navigation]);


    return (
        <section
            id="testimonials"
            className="testimonials image-background"
        >
            <Container>
                <Row>
                    <Col md={12}>
                        <Swiper
                            slidesPerView={1}
                            autoplay={{delay: 3000}}
                            loop={true}
                            navigation
                            centeredSlides={true}
                            effect={"fade"}
                            fadeEffect={{crossFade: true}}
                        >
                            {testimonials.map((testimonial, index) => (
                                <SwiperSlide key={index}>
                                    <div style={{display: "flex", justifyContent: "center", flexDirection: "column"}}>
                                        <h5>"{testimonial.comment}"</h5>
                                        <p>{testimonial.author}</p>
                                    </div>
                                </SwiperSlide>
                            ))}
                        </Swiper>
                    </Col>
                </Row>
            </Container>
        </section>
    );
}

export default Testimonials;
