import jwt_decode from "jwt-decode";

export const useAuth = () => {
    try {
        const token = localStorage.getItem("token")
        const {exp} = jwt_decode(token)
        return !(exp < (new Date().getTime() + 1) / 1000)
    } catch (e) {
        localStorage.removeItem("token")
        return false
    }
};

export const useAuthInfo = () => {
    try {
        const token = localStorage.getItem("token")
        const {data} = jwt_decode(token)
        return data
    } catch (e) {
        return false
    }
}

export default useAuth
