import React from 'react';
import "../../assets/css/contactpage/contact.sass"
import {Col, Container, Row} from "react-bootstrap";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faMapMarked, faPhone, faClock} from "@fortawesome/free-solid-svg-icons";
import {faFacebook, faInstagram} from "@fortawesome/free-brands-svg-icons";

function Contact() {
    return (
        <section className={"contact-page"}>
            <Container>
                <Row className="text-center">
                    <Col sm={12}>
                        <div>
                            <h2>Heb je een vraag of zit je met een probleem? </h2>
                            <p>Aarzel zeker niet om ons te contacteren als je een wild idee of een simpele vraag
                                hebt!</p>
                        </div>
                    </Col>
                    <Col md={4}>
                        <div className="detail">
                            <FontAwesomeIcon icon={faMapMarked}/>
                            <h4><span>Je kan ons vinden in</span></h4>
                            <h3>Jeugdhuis ODT</h3>
                            <p>
                                Markt 3 <br/>
                                1840 Londerzeel <br/>
                                België
                            </p>
                        </div>
                    </Col>

                    <Col md={4}>
                        <div className="detail">
                            <FontAwesomeIcon icon={faPhone}/>
                            <h4><span>Onze contactgegevens</span></h4>
                            <h3>Contact</h3>
                            <div className={"email"}>
                                <a href={"mailto:bestuur@jhodt.be"}>bestuur@jhodt.be</a>
                                <a href={"mailto:bekerverhuur@jhodt.be"}>bekerverhuur@jhodt.be</a>
                                <a href={"mailto:torentalenten@jhodt.be"}>torentalenten@jhodt.be</a>
                            </div>
                        </div>
                    </Col>

                    <Col md={4}>
                        <div className="detail">
                            <FontAwesomeIcon icon={faClock}/>
                            <h4><span>Openingsuren</span></h4>
                            <h3>Check onze sociale media!</h3>
                            <div className="social-links">
                                <div className={"link-container"}>
                                    <a className="link facebook" href="https://www.facebook.com/jhodt/">
                                        <FontAwesomeIcon icon={faFacebook}/>
                                        <p>Facebook</p>
                                    </a>
                                </div>
                                <div className={"link-container"}>
                                    <a className="link instagram" href="https://www.instagram.com/jh_odt/">
                                        <FontAwesomeIcon icon={faInstagram}/>
                                        <p>Instagram</p>
                                    </a>
                                </div>
                            </div>
                        </div>
                    </Col>
                </Row>
            </Container>
        </section>
    );
}

export default Contact;
