import React from 'react';
import moment from "moment";

function Evenement({evenement}) {
    return (
        <div className="evenement_item">
            <img src={evenement.afbeelding}
                 alt={evenement.naam}/>
            <div className="evenement_item_hover">
                <div className="item_info">
                    <span>{evenement.naam}</span>
                    <em>{moment(evenement.datum).format("DD/MM/YYYY")}</em>
                </div>
            </div>
        </div>
    );
}

export default Evenement;
