import React from 'react';
import {Container, Row, Col} from "react-bootstrap";
import {Link} from "react-router-dom";

function Rental(props) {
    return (
        <Container>
            <Row>
                <Col md={{span: 8, offset: 2}} sm={12}>
                    <Row style={{textAlign: "center"}}>
                        <Col sm={12} style={{margin: "5rem 0"}}>
                            <h2 style={{marginBottom: "1.5rem"}}>Herbruikbare bekers</h2>
                            <p> Bij Onder De Toren denken we ook aan het milieu. Daarom kan u via
                                onderstaande knop onze Tritan-bekers voor uw evenement huren.
                            </p>
                            <div style={{marginTop:"2rem"}}>
                                <Link to={"/bekerverhuur/login"} className="button">Bekers huren</Link>
                            </div>
                        </Col>
                    </Row>
                </Col>
            </Row>
        </Container>
    );
}

export default Rental;
