import {useMutation} from "react-query";
import axios from "axios";

const login = (data) => {
    return axios.post(`${process.env.REACT_APP_API_URL}/auth/login`, data)
}

const registreer = (data) => {
    return axios.post(`${process.env.REACT_APP_API_URL}/auth/registreer`, data)
}

export const useLogin = () => {
    return useMutation(login)
}

export const useRegistreer = () => {
    return useMutation(registreer)
}
