import React from 'react';
import {Col} from "react-bootstrap";
import "../../assets/css/bestuursledenpage/bestuursleden.sass"
import moment from "moment"

function Bestuurslid({bestuurslid}) {
    const jaar = moment(bestuurslid.startdatum).year()
    const unknown = "https://s3.eu-west-1.amazonaws.com/odt.website/bestuursleden/768893910487138335.jpg"

    return (
        <Col sm={12} md={4} className={"bestuurslid"}>
            <div className="bestuurslid-info">
                <img src={bestuurslid.afbeelding ? bestuurslid.afbeelding : unknown} alt={bestuurslid.voornaam}/>
                <div className="overlay">
                    <h6>{`${bestuurslid.voornaam} ${bestuurslid.familienaam}`}</h6>
                    <p>Bestuurslid sinds {jaar}</p>
                </div>
            </div>
            <h6>{bestuurslid.functie}</h6>
        </Col>
    );
}

export default Bestuurslid;
