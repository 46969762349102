import React, {useState} from 'react';
import {Col, Container, Row} from "react-bootstrap";
import "../../assets/css/evenementenpage/kalender.sass"
import moment from "moment";
import {useEvenementenData} from "../../data/UseEvenementenData";

function Kalender() {
    const [evenementen, setEvenementen] = useState([])
    const onSucces = (data) => {
        setEvenementen(data.evenementen)
    }
    const {isLoading} = useEvenementenData(onSucces)

    if (isLoading) {
        return <div>Loading...</div>
    }

    return (
        <section className="kalender">
            <div>
                <svg id="beerBg" preserveAspectRatio="none" viewBox="0 0 100 102" height="100" width="100%"
                     version="1.1"
                     xmlns="http://www.w3.org/2000/svg">
                    <path d="M0 0 L50 100 L100 0 Z" fill="#fff" stroke="#fff"/>
                </svg>
                <Container>
                    <Row className="justify-content-center">
                        <Col sm={12}>
                            <h5>Kalender</h5>
                        </Col>
                        <Col sm={12}>
                            <ul className="list-group-flush">
                                {evenementen.slice(0, 5).map((evenement, index) => (
                                    <li key={index}
                                        className="list-group-item d-flex justify-content-between align-items-center">
                                        {evenement.naam}
                                        <span
                                            className="badge badge-light badge-pill">{moment(evenement.datum).format("DD/MM/YYYY")}</span>
                                    </li>
                                ))}
                            </ul>
                        </Col>
                    </Row>
                </Container>
            </div>
        </section>
    );
}

export default Kalender;
