import axios from "axios";
import {useQuery} from "react-query";

const fetchEvenementen = () => {
    return axios.get(`${process.env.REACT_APP_API_URL}/evenementen/laatste`)
}

export const useEvenementenData = (onSuccess, onError) => {
    return useQuery('fetchEvenementen', fetchEvenementen, {
        onSuccess,
        onError,
        select: (data => data.data)
    })
}
