import React, {useEffect, useState, Fragment} from 'react';
import Evenement from "./Evenement";
import "../../assets/css/evenementenpage/evenementen.sass"
import Masonry from 'react-masonry-css'
import {Col, Container, Row} from "react-bootstrap";
import {useEvenementenData} from "../../data/UseEvenementenData";

function Evenementen() {
    const [evenementen, setEvenementen] = useState([])
    const [categorieen, setCategorieen] = useState([])
    const onSucces = (data) => {
        setEvenementen(data.evenementen)
        setCategorieen(data.categorieen)
    }
    const {isLoading} = useEvenementenData(onSucces)
    const [selectie, setSelectie] = useState([])
    const [keuze, setKeuze] = useState("*")

    useEffect(() => {
            if (keuze === "*") {
                setSelectie(evenementen)
            } else if (keuze === "Upcoming") {
                setSelectie(evenementen.filter(evenement => new Date(evenement.datum) >= new Date()))
            } else {
                setSelectie(evenementen.filter(evenement => evenement.categorieId === keuze))
            }
    }, [keuze, evenementen])

    const handleClick = (event, k) => {
        event.preventDefault()
        setKeuze(k)
    }

    const breakpointColumnsObj = {
        default: 3,
        950: 2,
        750: 1
    };

    if (isLoading) {
        return <div>Loading...</div>
    }

    return (
        <section className="evenement">
            <Container>
                <Row>
                    <Col sm={12}>
                        <p className={"evenementen-text"}>
                            Het jeugdhuis organiseert ook tal van
                            evenementen. Hier kan je alvast enkele affiches van evenementen bekijken.
                        </p>
                    </Col>
                    <Fragment>
                        <Col md={3} sm={12}>
                            <div className="categories-grid">
                                <nav className="categories">
                                    <ul className="evenement_filter">
                                        <li>
                                            <p
                                                className={keuze === "*" ? "active" : ""}
                                                onClick={event => {
                                                    handleClick(event, "*")
                                                }}
                                            >
                                                Alles
                                            </p>
                                        </li>
                                        <li>
                                            <p
                                                className={keuze === "Upcoming" ? "active" : ""}
                                                onClick={event => {
                                                    handleClick(event, "Upcoming")
                                                }}
                                            >
                                                Upcoming
                                            </p>
                                        </li>
                                        {categorieen.map((categorie, index) => (
                                            <li key={index}>
                                                <p
                                                    className={keuze === categorie.id ? "active" : ""}
                                                    onClick={event => {
                                                        handleClick(event, categorie.id)
                                                    }}
                                                >
                                                    {categorie.naam}
                                                </p>
                                            </li>
                                        ))}
                                    </ul>
                                </nav>
                            </div>
                        </Col>
                        <Col md={9}>
                            <div className="evenement_container">
                                <Masonry
                                    breakpointCols={breakpointColumnsObj}
                                    className="my-masonry-grid"
                                    columnClassName="my-masonry-grid_column"
                                >
                                    {selectie.map((evenement, index) => (
                                        <Evenement key={index} evenement={evenement}/>))}
                                </Masonry>
                            </div>
                        </Col>
                    </Fragment>
                </Row>
            </Container>
        </section>
    );
}

export default Evenementen;
