import axios from "axios";
import {useQuery} from "react-query";

const fetchMenu = () => {
    return axios.get(`${process.env.REACT_APP_API_URL}/consumpties/menu`)
}

export const useMenuData = (onSuccess, onError) => {
    return useQuery('fetchMenu', fetchMenu, {
        onSuccess,
        onError,
        select: (data => data.data)
    })
}
