import React, {Fragment} from 'react';
import Feature from "./Feature";
import { library } from '@fortawesome/fontawesome-svg-core'
import { faBeer, faGlassCheers, faTree, faHome } from '@fortawesome/free-solid-svg-icons'
import {Container, Row} from "react-bootstrap"
import "../../assets/css/homepage/features.sass"
import features from "../../assets/data/features.json"

library.add(faBeer, faGlassCheers, faTree, faHome)

function Features(props) {
    return (
        <Fragment>
            <div className="features">
                <Container>
                    <Row style={{textAlign: "center"}} id="features">
                        {features.map((feature, index) => <Feature key={index} title={feature.title} description={feature.description} icon={feature.icon}/> )}
                    </Row>
                </Container>
            </div>
            <div className="fill-left"/>
        </Fragment>
    );
}

export default Features;
