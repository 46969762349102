import React from 'react';
import {Container, Row, Col} from "react-bootstrap";
import "../../assets/css/assortimentpage/drankvdmaand.sass"
import {useDrankVdMaandData} from "../../data/UseDrankVdMaandData";

function DrankVdMaand() {
    const {isLoading, data} = useDrankVdMaandData()

    if (isLoading) {
        return <div>Loading...</div>
    }

    return (
        <section>
            <Container className={"text-center drank-vd-maand"}>
                <h3>Drank Van De Maand</h3>
                <Row>
                    <Col md={6} sm={12} className="text-center">
                        <img id="drinkOTMonth"
                             src={data?.afbeelding}
                             alt="drankvdmaand"/>
                    </Col>
                    <Col md={{span: 5, offset: 1}} sm={12} className="align-self-center drank-vd-maand-text">
                        <h4>{data?.naam}</h4>
                        <p>
                            {`${data?.omschrijving} Deze maand verkrijgbaar in uw favoriete jeugdhuis
                                voor
                                maar € ${data?.prijs?.toFixed(2).replace(".", ",")}.`}
                        </p>
                    </Col>
                </Row>
            </Container>
        </section>
    );
}

export default DrankVdMaand;
