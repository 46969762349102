import React, {Fragment} from 'react';
import HeaderSmall from "../components/global/HeaderSmall";
import Bestuursleden from "../components/bestuursledenPage/Bestuursleden";
import NavBar from "../components/global/NavBar";
import AnimationContainer from "../components/global/AnimationContainer";
import {Helmet} from "react-helmet";

function BestuursledenPage() {
    return (
        <Fragment>
            <Helmet>
                <title>ODT - Bestuursleden</title>
                <meta name="description" content="De helden die voor u het jeugdhuis uitbaten hebben vele talenten, die zij ten volle benutten om van ODT een nog leukere plek te maken! Samen zorgen zij ervoor dat het jeugdhuis voldoende drank heeft en leuke evenementen kan organiseren."/>
            </Helmet>
            <NavBar/>
            <AnimationContainer>
                <HeaderSmall title={"Bestuursleden"}/>
                <Bestuursleden/>
            </AnimationContainer>
        </Fragment>
    );
}

export default BestuursledenPage;
