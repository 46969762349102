import React, {Fragment} from 'react';
import RegistreerForm from "../components/verhuurpage/RegistreerForm";
import {Container} from "react-bootstrap";
import NavBar from "../components/global/NavBar";
import HeaderSmall from "../components/global/HeaderSmall";
import AnimationContainer from "../components/global/AnimationContainer";
import {Helmet} from "react-helmet";

function RegistreerPage() {
    return (
        <Fragment>
            <Helmet>
                <title>ODT - Registreren</title>
            </Helmet>
            <NavBar/>
            <AnimationContainer>
                <HeaderSmall title={"Registreren"}/>
                <Container>
                    <RegistreerForm/>
                </Container>
            </AnimationContainer>
        </Fragment>
    );
}

export default RegistreerPage;
