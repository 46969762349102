import React from 'react';

function Logo(props) {
    return (
        <svg
            viewBox="0 0 6250 6250"
            xmlns="http://www.w3.org/2000/svg"
            fillRule="evenodd"
            clipRule="evenodd"
            strokeMiterlimit={10}
            {...props}
        >
            <path
                d="M6012.336 3110.94c0 1603.063-1299.542 2902.606-2902.606 2902.606-1603.064 0-2902.606-1299.543-2902.606-2902.607 0-1603.063 1299.542-2902.606 2902.606-2902.606 1603.064 0 2902.606 1299.543 2902.606 2902.606z"
                fill="none"
                stroke="#1d1d1b"
                strokeWidth={208.3335}
            />
            <path
                d="M3113.04 2412.56v-17.791s-1127.618-39.58-1964.097 796.896c0 0 856.709 868.147 1964.097 868.147v-28.83m0 0v-17.595m0-1579.718v-21.109m-3.517-17.883s1127.618-43.888 1964.098 792.596c0 0-856.71 868.139-1964.098 868.139"
                fill="none"
                stroke="#1d1d1b"
                strokeWidth={41.6667}
            />
            <path
                d="M3340.057 3155.173c-2.092 46.967-38.559 78.7-50.296 88.134.57-25.6-2.604-64.492-23.088-104.225-8.183-15.88-18.395-35.146-40.487-51.046-21.742-15.642-43.833-20.05-55.83-21.521 13.322 46.637 19.497 87.82 22.43 120.058 1.542 16.93 5.108 63.475-.658 124.025-4.925 51.73-7.384 77.596-21.771 104.226-23.567 43.645-60.85 64.9-75.863 73.229-50.383 27.95-118.192 38.012-169.537 11.87-10.863-5.529-29.25-15.216-36.284-34.958-8.52-23.937 4.883-47.912 10.554-58.054 25.596-45.767 76.334-62.1 108.85-72.567 20.971-6.75 53.971-14.804 96.313-14.512 20.45-193.288-59.37-348.313-59.37-348.313 28.366-23.75 108.187-32.325 108.187-32.325s114.783 66.63 169.541 154.367c13.238 21.216 18.238 40.779 17.309 61.612m-228.78-741.984c-453.229 0-820.642 367.417-820.642 820.647 0 453.23 367.413 820.646 820.642 820.646 453.23 0 820.647-367.417 820.647-820.646 0-453.23-367.417-820.647-820.647-820.647"
                fill="#1d1d1b"
                fillRule="nonzero"
            />
            <path
                d="M447.613 4100.895L3111.282 265.358 5774.95 4100.895H447.613z"
                fill="none"
                stroke="#1d1d1b"
                strokeWidth={83.3334}
            />
        </svg>
    )
}

export default Logo;
