import React from 'react';
import { Navigate } from 'react-router-dom'
import useAuth from "../../hooks/useAuth";

const PrivateRoute = ({ children }) => {
    const isAuthenticated = useAuth();
    return isAuthenticated ? children : <Navigate to="/verhuur/login" />;
}

export default PrivateRoute;
