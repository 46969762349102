import parsePhoneNumber from 'libphonenumber-js'
import iban from 'iban'

export function validateVoornaam(voornaam) {
    const re = /^[\w'\-,.][^0-9_!¡?÷¿/\\+=@#$%ˆ&*(){}|~<>;:[\]]+$/;
    if (re.test(String(voornaam))) {
        return voornaam.charAt(0).toUpperCase() + voornaam.slice(1);
    } else {
        return false
    }
}

export function validateFamilienaam(familienaam) {
    const re = /^[\w'\-,.][^0-9_!¡?÷¿/\\+=@#$%ˆ&*(){}|~<>;:[\]]+$/;
    if (re.test(String(familienaam))) {
        return familienaam.charAt(0).toUpperCase() + familienaam.slice(1);
    } else {
        return false
    }
}

export function validateEmail(email) {
    const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    if (re.test(String(email).toLowerCase())) {
        return email.toLowerCase()
    } else {
        return false
    }
}

export function validateWachtwoord(wachtwoord) {
    const re = /^(?=.*[A-Za-z])(?=.*\d)(?=.*[@$!%*#?&])[A-Za-z\d@$!%*#?&]{8,}$/
    if (re.test(String(wachtwoord))) {
        return wachtwoord
    } else {
        return false
    }
}

export function validateVereniging(vereniging) {
    const re = /[\w ]+$/;
    if (re.test(String(vereniging))) {
        return vereniging;
    } else {
        return false
    }
}

export function validatePhoneNumber(number) {
    const phoneNumber = parsePhoneNumber(number, 'BE')
    if ( phoneNumber && phoneNumber.isValid()) {
        return phoneNumber.formatInternational()
    } else {
        return false
    }
}

export function validateRekeningnummer(reknr) {
    if (iban.isValid(reknr)) {
        return iban.printFormat(reknr," ")
    } else {
        return false
    }
}
