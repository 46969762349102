import React from 'react';
import {Col, Container, Row} from "react-bootstrap";

export default ReservatieError;

function ReservatieError({error}) {
    return (
        <Container>
            <Row className={"form"}>
                <Col md={12} className={"mt-4"}>
                    <h3 className={"text-center"}>Reservatie plaatsen mislukt</h3>
                </Col>
                <Col md={12} className={"mt-1 ml-1 tappen"}>
                    <p>
                        Het plaatsen van de reservatie is mislukt. Probeer later opnieuw of contacteer een bestuurslid van Jeugdhuis Onder de Toren
                    </p>
                </Col>
            </Row>
        </Container>
    );
}
