import React, {useEffect, useState} from 'react';
import {useNavigate} from 'react-router-dom'
import "../../assets/css/forms.sass"
import {faAngleDoubleRight} from "@fortawesome/free-solid-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {useLogin} from "../../data/UseAuthenticationData";
import {validateEmail, validateWachtwoord} from "../../util/validator";

function LoginForm() {
    const navigate = useNavigate();
    const [error, setError] = useState({message: ""})
    const [loginData, setLoginData] = useState({email: "", wachtwoord: ""})
    const {mutate: login, isLoading, isError, error: authError, data, isSuccess} = useLogin()

    const changeValue = (e) => {
        setLoginData((old) => ({...old, [e.target.name]: e.target.value}))
    }
    const handleLogin = () => {
        if (controlData()) {
            login(loginData)
        }
    }

    const controlData = () => {
        setError({message: ""})
        const email = validateEmail(loginData.email)
        if (!email) {
            setError({message: "Ongeldige inloggegevens"})
            return false
        }
        const ww = validateWachtwoord(loginData.wachtwoord)
        if (!ww) {
            setError({message: "Ongeldige inloggegevens"})
            return false
        }
        return true
    }


    useEffect(() => {
        if (isSuccess && !isError) {
            localStorage.setItem("token", data?.data?.token)
            navigate("/bekerverhuur/reserveer")
        } else if (isError) {
            setError({message: authError.response?.data?.message})
        }
    }, [isSuccess, isError, data, authError, navigate])


    return (
        <div className={"ml-2 mt-4 form"}>
            <div className={"text-container"}>
                <input type="email" name="email" value={loginData.email} onChange={changeValue} disabled={isLoading}/>
                <span className={(loginData.email !== "" ? "active" : "")}>e-mailadres</span>
            </div>
            <div className={"text-container"}>
                <input type="password" name="wachtwoord" value={loginData.wachtwoord} onChange={changeValue}
                       disabled={isLoading}/>
                <span className={(loginData.wachtwoord !== "" ? "active" : "")}>wachtwoord</span>
            </div>
            <div className={"error-container"}>
                {error.message !== "" ? (<p>{error.message}</p>) : null}
            </div>
            <button onClick={handleLogin} className="float-left mt-3 form-btn" name="login"
                    disabled={isLoading}>Inloggen <FontAwesomeIcon icon={faAngleDoubleRight}/>
            </button>
        </div>
    );
}

export default LoginForm;
