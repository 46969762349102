import React from 'react';
import PropTypes from "prop-types"
import {faSignOutAlt} from "@fortawesome/free-solid-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import useAuth from "../../hooks/useAuth";
import {useNavigate} from "react-router-dom";

function HeaderSmall({title}) {
    const isAuthenticated = useAuth()
    const navigate = useNavigate()

    const handleUitloggen = () => {
        localStorage.removeItem("token")
        navigate("/bekerverhuur/login")
    }

    return (
        <div className="header-small">
            <div className="section-overlay"/>
            {isAuthenticated ? (
                <div className={"sign-out"}>
                    <button onClick={handleUitloggen}>
                        <p>uitloggen</p>
                        <FontAwesomeIcon icon={faSignOutAlt}/>
                    </button>
                </div>) : null
            }
            <div className="page-title">
                <h1>{title}</h1>
                <div className="small-title">JH Onder De Toren</div>
            </div>
        </div>
    );
}

HeaderSmall.propTypes = {
    title: PropTypes.string
}

HeaderSmall.defaultProps = {
    title: "Title"
}


export default HeaderSmall;
