import React from 'react';
import {Container} from "react-bootstrap";
import SwiperCore, {Autoplay} from 'swiper';
import {Swiper, SwiperSlide} from "swiper/react";
import "../../assets/css/assortimentpage/assortiment.sass"
import 'swiper/swiper.scss';

const Assortiment = () => {
    const images = ["Bock_Logo.png", "Cornet_Logo.png", "Duvel_Logo.png", "Estaminet_Logo.png", "Geuze_Logo.png", "Palm_Logo.png", "Strongbow_Logo.png"]
    SwiperCore.use([Autoplay]);

    return (
        <section>
            <div className="assortiment">
                <svg height="8rem" preserveAspectRatio="none" version="1.1" viewBox="0 0 100 102"
                     width="100%" xmlns="http://www.w3.org/2000/svg">
                    <path d="M0 0 L50 100 L100 0 Z" fill="#fff" stroke="#fff"/>
                </svg>
                <Container>
                    <h5 className="text-center">Ons Assortiment</h5>
                    <Swiper
                        slidesPerView={1}
                        breakpoints={{
                            480: {
                                slidesPerView: 3
                            }
                        }}
                        autoplay={{delay: 3000}}
                        loop={true}
                        centeredSlides={true}
                    >
                        {images.map((image, index) => (
                            <SwiperSlide key={index}>
                                <div style={{display: "flex", justifyContent: "center"}}>
                                    <img
                                        src={`${process.env.PUBLIC_URL}assets/img/assortiment/${image}`}
                                        alt={image}
                                        style={{height:"100%"}}
                                    />
                                </div>
                            </SwiperSlide>
                        ))}
                    </Swiper>
                </Container>
            </div>
        </section>
    );
};

export default Assortiment;
