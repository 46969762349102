import React from 'react';
import {motion} from "framer-motion";

function AnimationContainer({children}) {
    const variants = {
        fadeIn: {y: 0, opacity: 1, display: "block"},
        fadeOut: {y: 100, opacity: 0, onTransitionEnd: {display: "none"}}
    }

    return (
        <motion.div
            variants={variants}
            initial="fadeOut"
            animate="fadeIn"
            exit="fadeOut"
            transition={{duration: 0.5}}
        >
            {children}
        </motion.div>
    );
}

export default AnimationContainer;
