import React from 'react';

const Header = () => (
    <section className="box-intro bg-film">
        <div className="table-cell">
            <h1>JEUGDHUIS ODT</h1>
        </div>

        <div className="mouse">
            <div className="scroll"/>
        </div>
    </section>
);

export default Header;
