import axios from "axios";
import {useQuery} from "react-query";

const fetchDrankVdMaand = () => {
    return axios.get(`${process.env.REACT_APP_API_URL}/drankenvdmaand/huidige`)
}

export const useDrankVdMaandData = (onSuccess, onError) => {
    return useQuery('fetchDrankVdMaand', fetchDrankVdMaand, {
        onSuccess,
        onError,
        select: (data => data.data)
    })
}
