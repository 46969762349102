import React, {useRef} from 'react'
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'
import {faFacebook, faInstagram} from '@fortawesome/free-brands-svg-icons'
import {Link} from "react-router-dom"
import {Squash as Hamburger} from 'hamburger-react'
import {motion, useCycle} from "framer-motion";
import useDimensions from "../../hooks/useDimensions";
import Logo from "./Logo";
const NavBar = () => {
    const [isOpen, toggleOpen] = useCycle(false, true);
    const containerRef = useRef(null);
    const {height} = useDimensions(containerRef);

    const sidebar = {
        open: () => ({
            clipPath: `circle(${height * 2 + 200}px at calc(100vw - 70px) 40px)`,
            transition: {
                type: "spring",
                stiffness: 20,
                restDelta: 2
            }
        }),
        closed: {
            clipPath: "circle(30px at calc(100vw - 70px) 40px)",
            transition: {
                type: "spring",
                stiffness: 400,
                damping: 40
            }
        }
    };

    const links = {
        open: {
            y: 0,
            opacity: 1,
            visibility: "visible",
            transition: {
                y: {stiffness: 1000, velocity: -100}
            }
        },
        closed: {
            y: 50,
            opacity: 0,
            visibility: "hidden",
            transition: {
                y: {stiffness: 1000}
            }
        }
    };

    const menuItems = {
        open: {
            transition: {staggerChildren: 0.07, delayChildren: 0.2}
        },
        closed: {
            transition: {staggerChildren: 0.05, staggerDirection: -1}
        }
    };

    return (
        <header>
            <div className="container-fluid">
                <div className="navbar">
                    <div className={"navbar-background"}>
                        <Link to={"/"}>
                            <Logo id={"header-logo"}/>
                        </Link>
                    </div>
                    <div className="hamburger-menu">
                        <Hamburger toggled={isOpen} toggle={() => toggleOpen()} color={"#000000"} distance="sm"/>
                    </div>
                </div>
                <motion.nav
                    className={"navigation"}
                    initial={false}
                    animate={isOpen ? "open" : "closed"}
                    custom={height}
                    ref={containerRef}
                    variants={sidebar}
                    exit="closed"
                >
                    <motion.ul
                        className={"navigation-items"}
                        variants={menuItems}
                    >
                        <motion.li variants={links} className="box-label">Jeugdhuis Onder De Toren</motion.li>
                        <motion.li
                            variants={links}
                            whileHover={{scale: 1.1}}
                            whileTap={{scale: 0.95}}
                        >
                            <Link to={"/"}>Home</Link>
                        </motion.li>
                        <motion.li
                            variants={links}
                            whileHover={{scale: 1.1}}
                            whileTap={{scale: 0.95}}
                        >
                            <Link to={"/assortiment"}>Assortiment</Link>
                        </motion.li>
                        <motion.li
                            variants={links}
                            whileHover={{scale: 1.1}}
                            whileTap={{scale: 0.95}}
                        >
                            <Link to={"/bekerverhuur"}>Bekerverhuur</Link>
                        </motion.li>
                        <motion.li
                            variants={links}
                            whileHover={{scale: 1.1}}
                            whileTap={{scale: 0.95}}
                        >
                            <Link to={"/bestuursleden"}>Bestuursleden</Link>
                        </motion.li>
                        <motion.li
                            variants={links}
                            whileHover={{scale: 1.1}}
                            whileTap={{scale: 0.95}}
                        >
                            <Link to={"/evenementen"}>Evenementen</Link>
                        </motion.li>
                        <motion.li
                            variants={links}
                            whileHover={{scale: 1.1}}
                            whileTap={{scale: 0.95}}
                        >
                            <Link to={"/contact"}>Contact</Link>
                        </motion.li>
                        <motion.li variants={links} className="box-label">Volg ons</motion.li>
                        <li className="box-social">
                            <motion.a
                                variants={links}
                                whileHover={{color: "#3b5998", scale: 1.1}}
                                whileTap={{scale: 0.95}}
                                href="http://www.facebook.com/jhodt"
                            >
                                <FontAwesomeIcon icon={faFacebook}/>
                            </motion.a>
                            <motion.a
                                variants={links}
                                whileHover={{color: "#d6249f", scale: 1.1}}
                                whileTap={{scale: 0.95}}
                                href="https://www.instagram.com/jh_odt">
                                <FontAwesomeIcon icon={faInstagram}/>
                            </motion.a>
                        </li>
                    </motion.ul>
                </motion.nav>
            </div>
        </header>
    )
}

export default NavBar;
