import React, {Fragment} from 'react';
import {Col} from "react-bootstrap";
import Bekertype from "./Bekertype";

function Bekers({bekers, setReservatie, reservatie}) {

    const updateReservatie = (naam, aantal) => {
        setReservatie((old) => {
            if (old.bekers.some(b => b.naam === naam)) {
                return {...old, bekers: [...old.bekers.filter(b => b.naam !== naam), {naam, aantal}]}
            } else {
                return {...old, bekers: [...old.bekers, {naam, aantal}]}
            }
        })
    }

    return (
        <Fragment>
            {
                (reservatie.begindatum && reservatie.einddatum && reservatie.begindatum < reservatie.einddatum && bekers) && (

                    <Col md={12} className={"mt-4"}>
                        <h4>Bekers</h4>
                    </Col>
                )
            }
            {bekers && bekers.filter(b => b.aantal !== 0).length === 0 ? (
                <Col>
                    <div className={"pl-2"}>
                        <p>Geen bekers beschikbaar</p>
                    </div>
                </Col>
            ): null}

            {reservatie.begindatum && reservatie.einddatum && reservatie.begindatum < reservatie.einddatum && bekers && bekers.filter(b => b.aantal > 0).map((b) => (
                <Bekertype
                    key={b?.naam}
                    naam={b?.naam}
                    maxAantal={b?.aantal}
                    huurprijs={b?.huurprijs}
                    waarborgprijs={b?.waarborgprijs}
                    inhoud={b?.inhoud}
                    aantalBekersPerDoos={b?.aantalBekersPerDoos}
                    updateReservatie={updateReservatie}
                    gekozenAantal={reservatie?.bekers.find(be => be.naam === b.naam)?.aantal}
                />
            ))}
        </Fragment>
    );
}

export default Bekers;
