import {useMutation} from "react-query";
import axios from "axios";
import authHeader from "../util/auth-header";

const fetchBerschikbareBekers = (data) => {
    return axios.post(`${process.env.REACT_APP_API_URL}/dozen/beschikbaar`, data,{headers: authHeader()})
}

const plaatsReservatie = (data) => {
    return axios.post(`${process.env.REACT_APP_API_URL}/reservaties`, data,{headers: authHeader()})
}

export const useBeschikbareBekersData = (onSuccess, onError) => {
    return useMutation('fetchBeschikbareBekers', fetchBerschikbareBekers, {
        onSuccess,
        onError,
    })
}

export const usePlaatsReservatieData = (onSuccess, onError) => {
    return useMutation('plaatsReservatie', plaatsReservatie, {
        onSuccess,
        onError,
    })
}
