import React, {forwardRef, Fragment, useEffect, useState} from 'react';
import {Col} from "react-bootstrap";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import "../../assets/css/forms.sass"
import moment from "moment";

function PeriodeSelector({reservatie, updateReservatie}) {
    const [minDate] = useState(moment().add(5, 'days').toDate())
    const [maxDate, setMaxDate] = useState(moment().add(2, 'month').toDate())

    const PeriodeInput = forwardRef(({ value, onClick, naam }, ref) => (
        <div className={"text-container"} onClick={onClick}>
            <div>{value}</div>
            <span className={(value ? "active" : "")}>{naam}</span>
        </div>
    ));

    useEffect(() => {
        if (reservatie?.begindatum && reservatie?.begindatum < moment().add(2, "months").toDate()) {
            setMaxDate(moment(reservatie?.begindatum).add(4, 'day').toDate())
        } else {
            setMaxDate(moment().add(2, 'month').toDate())
        }
    }, [reservatie?.begindatum])

    useEffect(() => {
        if (reservatie?.begindatum > reservatie?.einddatum) {
            updateReservatie("einddatum", null)
        }
    }, [reservatie?.begindatum, reservatie?.einddatum])

    return (
        <Fragment>
            <Col md={12} className={"mt-4"}>
                <h4>Periode</h4>
            </Col>
            <Col md={6} className={"form"}>
                <DatePicker
                    selected={reservatie?.begindatum}
                    dateFormat="dd/MM/yyyy"
                    onChange={(date) => updateReservatie("begindatum", date)}
                    startDate={reservatie?.begindatum}
                    minDate={minDate}
                    maxDate={maxDate}
                    customInput={<PeriodeInput naam={"ophaaldatum"}/>}
                />
            </Col>
            <Col md={6} className={"form"}>
                <DatePicker
                    selected={reservatie?.einddatum}
                    dateFormat="dd/MM/yyyy"
                    onChange={(date) => updateReservatie("einddatum", date)}
                    selectsEnd
                    startDate={reservatie?.begindatum}
                    endDate={reservatie?.einddatum}
                    minDate={reservatie?.begindatum}
                    maxDate={maxDate}
                    customInput={<PeriodeInput naam={"afleverdatum"}/>}
                />
            </Col>
        </Fragment>
    );
}

export default PeriodeSelector;
