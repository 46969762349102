import {useQuery} from "react-query";
import axios from "axios";

const fetchBestuursleden = () => {
    return axios.get(`${process.env.REACT_APP_API_URL}/bestuursleden/bestuur`)
}

export const useBestuursledenData = (onSuccess, onError) => {
    return useQuery('fetchBestuursleden', fetchBestuursleden, {
        onSuccess,
        onError,
        select: (data => data.data)
    })

}
