import React from 'react';
import "../../assets/css/forms.sass"
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faAngleDoubleRight} from "@fortawesome/free-solid-svg-icons";
import {useNavigate} from "react-router-dom";

function RegisterRedirect() {
    const navigate = useNavigate();

    const handleRegister = () => {
        navigate("/bekerverhuur/registreer")
    }
    return (
        <div className="ml-2 mt-4 form">
            <p className="text-justify">Nog geen account? Registreren kan door op onderstaande knop te
                drukken.</p>
            <div className={"mt-4"}>
                <button className="float-left form-btn" name="registreer" onClick={handleRegister}>Registreren <FontAwesomeIcon
                    icon={faAngleDoubleRight}/></button>
            </div>
        </div>
    );
}

export default RegisterRedirect;
