import React, {useState} from 'react';
import {Col, Container, Row} from "react-bootstrap";
import {Link} from "react-router-dom";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faAngleDoubleRight} from "@fortawesome/free-solid-svg-icons";
import "../../assets/css/contact.sass"
import {validateEmail} from "../../util/validator";

function Contact() {
    const [email, setEmail] = useState("");
    const [emailText, setEmailText] = useState("Vul hier jouw e-mailadres in")
    const [emailStyle, setEmailStyle] = useState("")

    const changeEmailText = (text, status) => {
        setEmailText(text)
        setEmailStyle(status)
    }

    const changeEmail = (e) => {
        setEmail(e.target.value)
    }

    const subscribe = () => {
        if (validateEmail(email)) {
            changeEmailText("Succesvol geabonneerd!", " text-success")
            setEmail("")
        } else {
            changeEmailText("Uw opgegeven E-mailadres is ongeldig", " text-danger")
        }

    }

    return (
        <section id="contact"
                 className="contact justify-content-center">
            <Container>
                <Row className="justify-content-center">
                    <Col md={6} sm={12}>
                        <h4> Contacteer ons!</h4>
                        <p>
                            Wil je graag een feest organiseren in ons jeugdhuis of wil je nog meer informatie
                            over ons
                            jeugdhuis?
                            Aarzel dan zeker niet om ons te contacteren!
                        </p>
                        <Link to={"/contact"} id="submit_btn" className="default-btn">
                            Contacteren <FontAwesomeIcon icon={faAngleDoubleRight}/>
                        </Link>
                    </Col>
                    <Col md={{size: 5, offset: 1}} sm={12} className="justify-content-center">
                        <div className="newsletter">
                            <h4>Abonneer</h4>
                            <p>
                                Blijf op de hoogte van onze nieuwtjes!
                            </p>
                            <div className="form">
                                <div className={"text-container"}>
                                    <input type="text" name="email" onChange={changeEmail} value={email}/>
                                    <span className={(email !== "" && "active") + emailStyle}>{emailText}</span>
                                </div>
                            <button onClick={subscribe} className="mt-4 form-btn">
                                Abonneer <FontAwesomeIcon icon={faAngleDoubleRight}/>
                            </button>
                            </div>
                        </div>
                    </Col>
                </Row>
            </Container>
        </section>
    );
}

export default Contact;
