import React from 'react';
import {Col, Container, Row} from "react-bootstrap";
import "../../assets/css/assortimentpage/menu.sass"
import {useMenuData} from "../../data/UseMenuData";

function Menu() {
    const {isLoading, isFetching, data} = useMenuData()

    if (isLoading || isFetching) {
        return <div>Loading...</div>
    }

    return (
        <Container className={"menu"}>
            <Row style={{justifyContent: "center"}}>
                <Col md={12} sm={12}>
                    <h3 style={{marginTop: "1rem", textAlign: "center"}}>Menu</h3>
                </Col>
                <div style={{justifyContent: "center"}}>
                    <Col xs={12} md={12}>
                        <Row>
                            {data?.map((category, index) => (
                                <Col sm={12} md={6} key={index} style={{marginTop: "1rem", marginBottom: "1rem"}}>
                                    <h4 style={{textAlign: "left", marginTop: ".5rem"}}>{category.naam}</h4>
                                    {category.consumpties.map((consumptie, index) => (
                                        <Row key={index}>
                                            <div className={"col-8"}>
                                                <p style={{textAlign: "left", color: "#0C0C0C", paddingLeft: ".8rem"}}
                                                   className="drinkName mb-sm-0">{consumptie.naam}</p>
                                            </div>
                                            <div className={"col-4"}>
                                                <p style={{textAlign: "right"}}>€ {consumptie.verkoopprijs.toFixed(2).replace(".", ",")}</p>
                                            </div>
                                        </Row>
                                    ))}
                                </Col>
                            ))}
                        </Row>
                    </Col>
                </div>
            </Row>
        </Container>
    )
}

export default Menu;
