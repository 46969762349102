import React, {useState, Fragment} from 'react';
import NavBar from "../components/global/NavBar";
import HeaderSmall from "../components/global/HeaderSmall";
import ReservatieForm from "../components/verhuurpage/ReservatieForm";
import {usePlaatsReservatieData} from "../data/UseBekerverhuurData";
import {Oval} from "react-loader-spinner";
import {Container} from "react-bootstrap";
import ReservatieSucces from "../components/verhuurpage/ReservatieSucces";
import ReservatieError from "../components/verhuurpage/ReservatieError";
import {useAuthInfo} from "../hooks/useAuth";
import AnimationContainer from "../components/global/AnimationContainer";
import {Helmet} from "react-helmet";

function ReservatiePage() {
    const [reservatie, setReservatie] = useState({
        begindatum: null,
        einddatum: null,
        bekers: [],
        email: useAuthInfo().email
    })
    const {mutate: plaatsReservatie, data: resultaat, isLoading, error, isSuccess, isError} = usePlaatsReservatieData()

    const updateReservatie = (key, value) => {
        setReservatie((old) => ({...old, [key]: value}))
    }

    const loadingUI = (
        <Container className={"d-flex justify-content-center mt-5"}>
            <Oval
                color="#777"
                secondaryColor="#ccc"
                height={80}
                width={80}
                strokeWidth={2}
                ariaLabel={"Laden..."}
            />
        </Container>
    )

    const plaatsReservatieUI = (
        <ReservatieForm
            reservatie={reservatie}
            setReservatie={setReservatie}
            updateReservatie={updateReservatie}
            plaatsReservatie={plaatsReservatie}
        />
    )

    const reservatieSuccesUI = (
        <ReservatieSucces
            reservatie={resultaat?.data}
            isSucces={isSuccess}
        />
    )

    const reservatieErrorUI = (
        <ReservatieError
            error={error}
        />
    )

    return (
        <Fragment>
            <NavBar/>
            <Helmet>
                <title>ODT - Bekerverhuur</title>
            </Helmet>
            <AnimationContainer>
                <HeaderSmall title={"Tritanbekers"}/>
                {
                    isLoading ? loadingUI : isSuccess ? reservatieSuccesUI : isError ? reservatieErrorUI : plaatsReservatieUI
                }
            </AnimationContainer>
        </Fragment>
    );
}

export default ReservatiePage;
