import React, {Fragment} from 'react';
import HeaderSmall from "../components/global/HeaderSmall";
import Evenementen from "../components/evenementenpage/Evenementen";
import Kalender from "../components/evenementenpage/Kalender";
import NavBar from "../components/global/NavBar";
import AnimationContainer from "../components/global/AnimationContainer";
import {Helmet} from "react-helmet";

function EvenementenPage() {

    return (
        <Fragment>
            <Helmet>
                <title>ODT - Evenementen</title>
                <meta name="description" content="Het jeugdhuis organiseert ook tal van evenementen. Hier kan je alvast enkele affiches en datums ervan bekijken."/>
            </Helmet>
            <NavBar/>
            <AnimationContainer>
                <HeaderSmall title={"Evenementen"}/>
                <Evenementen/>
                <Kalender/>
            </AnimationContainer>
        </Fragment>
    );
}

export default EvenementenPage;
