import React from "react"
import {Routes, Route, useLocation, Navigate} from "react-router-dom";
import HomePage from "./pages/HomePage";
import AssortimentPage from "./pages/AssortimentPage";
import EvenementenPage from "./pages/EvenementenPage";
import BestuursledenPage from "./pages/BestuursledenPage";
import ContactPage from "./pages/ContactPage";
import LoginPage from "./pages/LoginPage";
import RegistreerPage from "./pages/RegistreerPage";
import PrivateRoute from "./components/global/PrivateRoute";
import ReservatiePage from "./pages/ReservatiePage";
import Footer from "./components/global/Footer";
import {QueryClient, QueryClientProvider} from "react-query";
import {AnimatePresence} from "framer-motion";
import AuthenticationRoute from "./components/global/AuthenticationRoute";
import {Helmet} from "react-helmet";


const queryClient = new QueryClient()

const App = () => {
    const location = useLocation()
    return (
        <QueryClientProvider client={queryClient}>
            <AnimatePresence exitBeforeEnter initial={true}>
                <Helmet>
                    <title>ODT</title>
                    <meta name="description" content="Jeugdhuis Onder de Toren" />
                    <meta name="theme-color" content="#000000" />
                </Helmet>
                <Routes location={location} key={location.pathname}>
                    <Route exact path={"/"} element={<HomePage/>}/>
                    <Route exact path={"/assortiment"} element={<AssortimentPage/>}/>
                    <Route exact path={"/evenementen"} element={<EvenementenPage/>}/>
                    <Route exact path={"/bekerverhuur/login"} element={<AuthenticationRoute redirect={"/bekerverhuur/reserveer"}><LoginPage/></AuthenticationRoute>}/>
                    <Route exact path={"/bekerverhuur/registreer"} element={<AuthenticationRoute redirect={"/bekerverhuur/reserveer"}><RegistreerPage/></AuthenticationRoute>}/>
                    <Route exact path={"/bekerverhuur/reserveer"} element={<PrivateRoute><ReservatiePage/></PrivateRoute>}/>
                    <Route exact path={"/bekerverhuur/*"} element={<Navigate to={"/bekerverhuur/login/"}/>}/>
                    <Route exact path={"/bestuursleden"} element={<BestuursledenPage/>}/>
                    <Route exact path={"/contact"} element={<ContactPage/>}/>
                </Routes>
                <Footer/>
            </AnimatePresence>
        </QueryClientProvider>
    )
}

export default App;
