import React, {Fragment} from 'react';
import Header from "../components/global/Header";
import About from "../components/homepage/About";
import Features from "../components/homepage/Features";
import Rental from "../components/homepage/Rental";
import Testimonials from "../components/homepage/Testimonials";
import NavBar from "../components/global/NavBar";
import AnimationContainer from "../components/global/AnimationContainer";
import {Helmet} from "react-helmet";

function HomePage() {

    return (
        <Fragment>
            <Helmet>
                <title>ODT - Welkom</title>
                <meta name="description" content="Welkom op de website van Jeugdhuis Onder de Toren in Londerzeel! Hier vindt u alle informatie over het jeugdhuis, zo kan u onder meer onze menukaart raadplegen, aanvragen doen om het jeugdhuis te huren, de bestuursleden beter leren kennen, onze toekomstige evenementen bekijken en tevens nostalgisch terugblikken op vele schone avonden in zowat het gezelligste jeugdhuis van 1840!" />

            </Helmet>
            <NavBar/>
            <AnimationContainer>
                <Header/>
                <About/>
                <Features/>
                <Rental/>
                <Testimonials/>
            </AnimationContainer>
        </Fragment>
    );
}

export default HomePage;
