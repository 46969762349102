import React from 'react';
import '../../assets/css/homepage/about.sass';
import {Container, Row, Col} from "react-bootstrap";

const About = () => {
    return (
        <section style={{margin: "2.5rem 0"}}>
            <Container>
                <Row>
                    <Col md="12" style={{textAlign: "center"}}>
                        <img
                            src={process.env.PUBLIC_URL + "/assets/img/logo.png"}
                            alt="Logo Jeugdhuis Onder de Toren"
                            id="about-logo"
                        />
                        {/*TODO: Tekst ophalen uit MongoDB database via de api*/}
                        <p style={{marginTop: "2rem"}}>
                            Proficiat, u heeft ons ook op het wereldwijde web gevonden. Net zoals in het
                            echte leven is iedereen welkom op de digitale pagina van Jeugdhuis Onder de Toren in
                            Londerzeel.
                            Hier vindt u alle informatie over het jeugdhuis, zo kan u onder meer onze menukaart
                            raadplegen,
                            aanvragen doen om het jeugdhuis te huren, de bestuursleden beter leren kennen, onze
                            toekomstige
                            evenementen bekijken en tevens nostalgisch terugblikken op vele schone avonden in zowat het
                            gezelligste jeugdhuis van 1840! U leest het, teveel om op te noemen in een korte en heldere
                            zin.
                            Indien het lezen u minder vlot afgaat, mag u dit alles ook steeds live en in’t echt komen
                            beleven op een vrijdag of zaterdagavond. Wij bedienen u met veel plezier, alvast tot dan!
                        </p>
                    </Col>
                </Row>
            </Container>
        </section>
    )
};

export default About;
